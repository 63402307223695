<template>
  <div class="titulo">
    <div class="margem container">
      <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Ativo</h2>
    </div>
  </div>

  <form ref="formulario">
    <div class="margem container">
      <div class="bloco margem">
        <div class="titulo"><h3>Informações</h3></div>

        <div class="grid-4">
          <div class="coluna">
            <label for="dataAquisicao">Data Aquisição</label>
            <input type="date" id="dataAquisicao" v-model="dataAquisicao" />
          </div>
          <div class="coluna">
            <label for="unidade">Unidade</label>
            <select id="unidade" v-model="unidade" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="filial in filiais" :key="filial.id" :value="filial.id">{{ filial.local_nome }}</option>
            </select>
          </div>
          <div class="coluna">
            <label for="valor">Valor</label>
            <input type="text" id="valor" v-model="valor" @input="formatarValor" @blur="formatarValorBlur" required autocomplete="off" />
          </div>
          <div class="coluna">
            <label for="subCategoria">Tipo</label>
            <select id="subCategoria" v-model="subCategoria" required>
              <optgroup v-for="categoria in categorias" :key="categoria.id" :label="categoria.nome">
                <option v-for="sub in categoria.subCategoria" :key="sub.id" :value="sub.id">{{ sub.codigo }} - {{ sub.nome }}</option>
              </optgroup>
            </select>
          </div>
          <div class="coluna col-2">
            <label for="descricao">Descrição</label>
            <input type="text" id="descricao" v-model="descricao" autocomplete="off" />
          </div>
          <div class="coluna col-1">
            <label for="notaFiscal">Nota Fiscal</label>
            <input type="text" id="notaFiscal" v-model="notaFiscal" autocomplete="off" />
          </div>

          <div class="coluna col-1">
            <label for="dtFinalGarantia">Data Final da Garantia</label>
            <input type="date" id="dtFinalGarantia" v-model="dtFinalGarantia" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!modoCadastro">
      <div class="margem container">
        <div class="bloco margem">
          <div class="titulo"><h3>Anexos</h3></div>
          <div class="linha">
            <ul>
              <li v-for="anexo in anexos" :key="anexo.id">
                <a :href="getAnexoUrl(anexo.path)" target="_blank">{{ anexo.nome }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="margem container">
      <div class="bloco margem">
        <div class="titulo"><h3>Características</h3></div>

        <div class="grid-4">
          <div class="">
            <label for="marca">Marca</label>
            <input type="text" id="marca" v-model="marca" autocomplete="off" />
          </div>
          <div class="">
            <label for="modelo">Modelo</label>
            <input type="text" id="modelo" v-model="modelo" autocomplete="off" />
          </div>
          <div class="">
            <label for="codigoBarras">Código de barras</label>
            <input type="text" id="codigoBarras" v-model="codigoBarras" autocomplete="off" />
          </div>
          <div class="">
            <label for="imei">IMEI</label>
            <input type="text" id="imei" v-model="imei" autocomplete="off" />
          </div>
          <div class="">
            <label for="numeroSerie">Nr. Série</label>
            <input type="text" id="numeroSerie" v-model="numeroSerie" autocomplete="off" />
          </div>
          <div class="col-2">
            <label for="fornecedor">Fornecedor</label>
            <input type="text" id="fornecedor" v-model="fornecedorNome" @input="filtrarFornecedores" @focus="mostrarSugestoes = true" @blur="ocultarSugestoes" autocomplete="off" />
            <ul v-if="mostrarSugestoes && fornecedoresFiltrados.length" class="autocomplete">
              <li v-for="fornecedor in fornecedoresFiltrados" :key="fornecedor.id" @mousedown.prevent="selecionarFornecedor(fornecedor)">
                {{ fornecedor.nome }}
              </li>
            </ul>
          </div>
          <div class="">
            <label for="vidaUtil">Vida Útil</label>
            <input type="number" id="vidaUtil" v-model="vidaUtil" autocomplete="off" />
          </div>
          <div class="col-4">
            <label for="complemento">Complemento</label>
            <input type="text" id="complemento" v-model="complemento" autocomplete="off" />
          </div>
        </div>
        <div class="alinha-centro">
          <br />
          <button style="margin-right: 10px" type="button" v-if="!modoCadastro" @click="cancelarAcao" class="acao-secundaria">Cancelar</button>
          <button type="button" @click="salvarAtivo" class="acao-secundaria">Salvar</button>
          <br />
        </div>
      </div>
    </div>
  </form>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getUnidade, getCategoria, getFornecedor, gravarAtivo, getBuscarAtivo, atualizarAtivo } from "@/services/api";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "CadastroAtivosView",
  data() {
    return {
      dataAquisicao: "",
      unidade: "",
      descricao: "",
      dtFinalGarantia: "",
      notaFiscal: "",
      valor: "",
      subCategoria: null,
      marca: "",
      modelo: "",
      codigoBarras: "",
      imei: "",
      complemento: "",
      numeroSerie: "",
      fornecedor: null,
      fornecedorNome: "",
      vidaUtil: "",
      imagem: "",
      categorias: [],
      filiais: [],
      fornecedores: [],
      fornecedoresFiltrados: [],
      mostrarSugestoes: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      usuario_id: null,
      modoCadastro: true,
      anexos: [],
    };
  },
  computed: {
    ...mapGetters(["getSelectedItemId"]),
  },
  watch: {
    getSelectedItemId(newVal) {
      if (newVal) {
        this.modoCadastro = false;
        this.carregarAtivo(newVal);
      } else {
        this.resetFormulario();
      }
    },
  },
  mounted() {
    this.buscarFiliais();
    this.buscarCategorias();
    this.buscarFornecedores();
    const id = this.getSelectedItemId;
    if (id) {
      this.modoCadastro = false;
      this.carregarAtivo(id);
    } else {
      this.resetFormulario();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetFormulario();
    this.clearSelectedItem();
    next();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    ...mapMutations(["clearSelectedItem"]),
    resetFormulario() {
      this.dataAquisicao = "";
      this.unidade = "";
      this.descricao = "";
      this.dtFinalGarantia = "";
      this.notaFiscal = "";
      this.valor = "";
      this.subCategoria = null;
      this.marca = "";
      this.modelo = "";
      this.codigoBarras = "";
      this.imei = "";
      this.complemento = "";
      this.numeroSerie = "";
      this.fornecedor = "";
      this.fornecedorNome = "";
      this.vidaUtil = "";
      this.imagem = "";
      this.anexos = [];
      this.modoCadastro = true;
    },
    async validate() {
      let valid = true;
      if (!this.dataAquisicao || !this.unidade || !this.valor || !this.subCategoria || !this.fornecedor) {
        valid = false;
        this.snackbarText = "Preencha todos os campos obrigatórios.";
        this.snackbarColor = "error";
        this.snackbarVisible = true;
      }
      return valid;
    },
    formatarValor(event) {
      const value = event.target.value.replace(/\D/g, "");
      const options = { style: "currency", currency: "BRL" };
      const formatted = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      this.valor = formatted;
    },
    formatarValorBlur() {
      if (this.valor) {
        const value = this.valor.replace(/\D/g, "");
        const options = { style: "currency", currency: "BRL" };
        this.valor = new Intl.NumberFormat("pt-BR", options).format(value / 100);
      }
    },
    handleSubCategoriaChange(value) {
      const selected = this.categorias.flatMap((cat) => cat.subCategoria).find((sub) => sub.id === value);
      if (selected) {
        this.subCategoria = value;
      } else {
        this.subCategoria = null;
      }
    },
    async salvarAtivo() {
      const formValido = await this.validate();
      if (formValido) {
        const dadosFormulario = {
          ativo_id: this.getSelectedItemId,
          dtAquisicao: this.dataAquisicao,
          unidade_id: parseInt(this.unidade),
          descricao: this.descricao,
          dtFinalGarantia: this.dtFinalGarantia,
          notaFiscal: this.notaFiscal,
          valor: parseFloat((this.valor || "").toString().replace("R$", "").replace(/\./g, "").replace(",", ".")),
          categoria_id: parseInt(this.subCategoria),
          marca: this.marca,
          modelo: this.modelo,
          imei: this.imei,
          codigoBarras: this.codigoBarras,
          nrSerie: this.numeroSerie,
          fornecedor_id: parseInt(this.fornecedor),
          vidaUtil: parseInt(this.vidaUtil),
          complemento: this.complemento,
          usuario_id: this.usuario_id,
        };

        try {
          if (this.modoCadastro) {
            await gravarAtivo(dadosFormulario);
            this.snackbarText = "Ativo cadastrado com sucesso!";
          } else {
            await atualizarAtivo(dadosFormulario);
            this.snackbarText = "Ativo atualizado com sucesso!";
          }
          this.snackbarColor = "success";
          this.snackbarVisible = true;
          this.$router.push({ name: "ListaAtivos" });
        } catch (error) {
          console.error("Erro ao enviar formulário:", error);
          this.snackbarText = "Falha ao processar o ativo.";
          this.snackbarColor = "error";
          this.snackbarVisible = true;
        }
      }
    },
    async carregarAtivo(id) {
      try {
        await this.buscarFornecedores();

        const response = await getBuscarAtivo({ id });
        const ativo = response.data;

        const date = new Date(ativo.dtAquisicao);
        const formattedDate = date.toISOString().split("T")[0];

        let formattedDate2 = "";
        if (ativo.dtFinalGarantia) {
          const date2 = new Date(ativo.dtFinalGarantia);
          formattedDate2 = date2.toISOString().split("T")[0];
        }

        this.dataAquisicao = formattedDate;
        this.unidade = ativo.unidade_id;
        this.descricao = ativo.descricao;
        this.dtFinalGarantia = formattedDate2;
        this.notaFiscal = ativo.notaFiscal;
        this.valor = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(ativo.valor);
        this.subCategoria = ativo.categoria_id;
        this.marca = ativo.marca;
        this.modelo = ativo.modelo;
        this.codigoBarras = ativo.codigoBarras;
        this.imei = ativo.imei;
        this.numeroSerie = ativo.nrSerie;
        this.fornecedor = ativo.fornecedor_id;
        this.fornecedorNome = this.fornecedores.find((f) => f.id === ativo.fornecedor_id)?.nome || "";
        this.vidaUtil = ativo.vidaUtil;
        this.complemento = ativo.complemento;
        this.anexos = ativo.anexos || [];
      } catch (error) {
        console.error("Erro ao carregar ativo:", error);
      }
    },
    async buscarFiliais() {
      try {
        const response = await getUnidade();
        this.filiais = response.data;
      } catch (error) {
        console.error("Erro ao buscar filiais:", error);
      }
    },
    async buscarCategorias() {
      try {
        const response = await getCategoria();
        this.categorias = response.data;
      } catch (error) {
        console.error("Erro ao buscar categorias superiores:", error);
      }
    },
    async buscarFornecedores() {
      try {
        const response = await getFornecedor();
        this.fornecedores = response.data;
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      }
    },
    filtrarFornecedores() {
      const query = this.fornecedorNome.toLowerCase();
      this.fornecedoresFiltrados = this.fornecedores.filter((fornecedor) => fornecedor.nome.toLowerCase().includes(query));
      this.mostrarSugestoes = true;
    },
    selecionarFornecedor(fornecedor) {
      this.fornecedor = fornecedor.id;
      this.fornecedorNome = fornecedor.nome;
      this.mostrarSugestoes = false;
    },
    ocultarSugestoes() {
      setTimeout(() => {
        this.mostrarSugestoes = false;
      }, 200);
    },
    cancelarAcao() {
      this.resetFormulario();
      this.clearSelectedItem();
      this.$router.push({ name: "ListaAtivos" });
    },
    getAnexoUrl(path) {
      return `${process.env.VUE_APP_ROOT_STORAGE}${path}`;
    },
  },
};
</script>

<style scoped>
.autocomplete {
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 412px !important;
}

.autocomplete li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete li:hover,
.autocomplete li.active {
  background-color: #f0f0f0;
}
</style>
