<template>
  <v-dialog v-model="detalhesDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes do Ativo</v-card-title>
      <v-card-text>
        <div><strong>Código:</strong> {{ selectedItem.codigo }}</div>
        <div><strong>Nome:</strong> {{ selectedItem.descricao }}</div>
        <div><strong>Valor:</strong> {{ formatarValorReal(selectedItem.valor) }}</div>
        <div><strong>Unidade:</strong> {{ selectedItem.unidade_nome }}</div>
        <div><strong>Marca:</strong> {{ selectedItem.marca }}</div>
        <div><strong>Modelo:</strong> {{ selectedItem.modelo }}</div>
        <div><strong>IMEI:</strong> {{ selectedItem.imei }}</div>
        <div><strong>Código de Barras:</strong> {{ selectedItem.codigoBarras }}</div>
        <div><strong>Número de Série:</strong> {{ selectedItem.nrSerie }}</div>
        <div><strong>Fornecedor:</strong> {{ selectedItem.fornecedor_nome }}</div>
        <div><strong>Vida Útil:</strong> {{ selectedItem.vidaUtil }}</div>
        <div><strong>Data Fim da Garantia:</strong> {{ selectedItem.dtFinalGarantia }}</div>
        <div><strong>Complemento:</strong> {{ selectedItem.complemento }}</div>

        <div><strong>Anexos:</strong></div>
        <ul>
          <li v-for="anexo in selectedItem.anexos" :key="anexo.id">
            <a :href="getAnexoUrl(anexo.path)" target="_blank">{{ anexo.nome }}</a>
          </li>
        </ul>

        <div class="alinha-v alinha-centro">
          <img :src="qrCodeUrl" />
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button class="acao-secundaria" @click="detalhesDialogVisible = false">Fechar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="movimentarDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Movimentar Ativo</v-card-title>

      <v-card-text v-if="selectedItem && selectedItem.status !== 'Em manutenção'">
        <div>
          <label for="movimentacaoTipo">Tipo de Movimentação</label>
          <select id="movimentacaoTipo" v-model="movimentacaoTipo" @change="carregarSelects">
            <option value="" selected disabled>Selecione</option>
            <option v-for="opcao in movimentacaoOpcoes" :key="opcao" :value="opcao">{{ opcao }}</option>
          </select>
        </div>
        <br />
        <div v-if="movimentacaoTipo === 'Local'">
          <div>
            <label for="unidadeSelecionada">Selecionar Unidade</label>
            <select id="unidadeSelecionada" v-model="unidadeSelecionada" required>
              <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{ unidade.local_nome }}</option>
            </select>
          </div>
          <div>
            <label for="localFisicoSelecionado">Local Físico</label>
            <select id="localFisicoSelecionado" v-model="localFisicoSelecionado" required>
              <option v-for="local in locaisFiltrados" :key="local.id" :value="local.id">{{ local.nome }}</option>
            </select>
          </div>
        </div>

        <div v-if="movimentacaoTipo === 'Responsável (Presente)'">
          <div>
            <label for="email">Usuário</label>
            <input type="text" id="email" v-model="email" required />
          </div>
          <div>
            <label for="password">Senha</label>
            <input type="password" id="password" v-model="password" required />
          </div>
        </div>

        <div v-if="movimentacaoTipo === 'Responsável (Distante)'">
          <div>
            <label for="usuarioSelecionado">Usuário</label>
            <select id="usuarioSelecionado" v-model="usuarioSelecionado" required>
              <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario.id">{{ usuario.name }}</option>
            </select>
          </div>
        </div>

        <div v-if="movimentacaoTipo === 'Manutenção'" class="alinha-v alinha-centro">
          <button class="acao-secundaria" @click="criarOSManutencao">Criar OS de Manutenção</button>
        </div>

        <div v-if="movimentacaoTipo === 'Descarga'">
          <div>
            <label for="motivoDescarga">Motivo</label>
            <select id="motivoDescarga" v-model="motivoDescarga" required>
              <option value="" disabled selected>Selecione</option>
              <option v-for="motivo in listaDescarga" :key="motivo.id" :value="motivo.id">{{ motivo.nome }}</option>
            </select>
          </div>
          <div>
            <label for="obs">Motivo da descarga do ativo</label>
            <textarea id="obs" v-model="obs" rows="3" required></textarea>
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="selectedItem && selectedItem.status === 'Em manutenção'">
        <div>Atualmente o ativo está em Manutenção.</div>
        <br />
        <div class="alinha-v alinha-centro">
          <button class="acao-secundaria" @click="movimentarEmUso">Movimentar para "Em uso".</button>
        </div>
      </v-card-text>

      <div v-if="movimentacaoTipo !== 'Manutenção'" class="alinha-v alinha-centro">
        <div v-if="selectedItem && selectedItem.status !== 'Em manutenção'">
          <button style="margin-right: 10px" class="acao-secundaria" @click="fecharMovimentarDialog">Cancelar</button>
          <button class="acao-secundaria" @click="salvarMovimentacao">Salvar</button>
        </div>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="pdfDialogVisible" max-width="400">
    <v-card>
      <v-card-title class="headline">Definir Dimensões do QR Code</v-card-title>
      <v-card-text>
        <v-text-field v-model="altura" label="Altura (px)" type="number" required></v-text-field>
        <v-text-field v-model="largura" label="Largura (px)" type="number" required></v-text-field>
      </v-card-text>

      <div class="linha alinha-centro alinha-v">
        <button style="margin-right: 10px" class="acao-secundaria" @click="pdfDialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" @click="gerarPdf">Gerar PDF</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Lista de Ativos</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <button @click="abrirModalPdf" class="acao-secundaria">Gerar PDF dos ativos selecionados</button>

      <table class="tabela">
        <thead>
          <tr>
            <th><input type="checkbox" @click="toggleAllSelection" /></th>
            <th>Código</th>
            <th>Nome</th>
            <th>Valor</th>
            <th>Local Físico</th>
            <th>Unidade</th>
            <th>Responsável</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedItems" :key="item.id">
            <td><input type="checkbox" v-model="selectedItems" :value="item.id" /></td>
            <td>{{ item.codigo }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ formatarValorReal(item.valor) }}</td>
            <td>{{ item.localFisico_nome }}</td>
            <td>{{ item.unidade_nome }}</td>
            <td>{{ item.responsavel_nome || "Almoxarifado" }}</td>
            <td style="white-space: nowrap">
              <div v-if="item.status !== 'Em manutenção'">{{ item.status }}</div>
              <div v-else>{{ item.protocolo_codigo }}</div>
            </td>
            <td>
              <div class="linha alinha-v alinha-centro">
                <button class="acao-secundaria" @click.stop="editarAtivo(item)">Editar</button>
              </div>
            </td>
            <td>
              <div class="linha alinha-v alinha-centro">
                <button class="acao-secundaria" @click.stop="abrirMovimentarDialog(item)">Movimentar</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
      </div>

      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { gerarQrcodePdf, gravarOrdemServico, getListarAtivos, getUnidade, getLocalFisico, movimentarAtivo, getMotivoAlerta, getlistarUsuarios } from "@/services/api";
import { mapActions } from "vuex";
import { formatarValorReal } from "@/utils/funcoesUtils";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "ListaAtivosView",
  data() {
    return {
      items: [],
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      movimentarDialogVisible: false,
      movimentacaoTipo: "",
      movimentacaoOpcoes: ["Local", "Responsável (Presente)", "Responsável (Distante)", "Manutenção", "Descarga"],
      unidades: [],
      locaisFisicos: [],
      unidadeSelecionada: null,
      localFisicoSelecionado: null,
      usuarios: [],
      usuarioSelecionado: null,
      email: "",
      password: "",
      selectedItem: null,
      obs: "",
      listaDescarga: [],
      motivoDescarga: "",
      detalhesDialogVisible: false,
      usuario_id: null,
      pdfDialogVisible: false,
      altura: null,
      largura: null,
      selectedItems: [],
    };
  },
  computed: {
    qrCodeUrl() {
      return `${process.env.VUE_APP_ROOT_STORAGE}${this.selectedItem.qrcode_path}`;
    },
    locaisFiltrados() {
      return this.locaisFisicos.filter((local) => local.local_id === this.unidadeSelecionada);
    },
    filteredItems() {
      const searchLower = this.search.toLowerCase();
      return this.items.filter((item) => {
        return (
          (item.codigo && item.codigo.toString().toLowerCase().includes(searchLower)) ||
          (item.descricao && item.descricao.toLowerCase().includes(searchLower)) ||
          (item.valor && item.valor.toString().includes(searchLower)) ||
          (item.localFisico_nome && item.localFisico_nome.toLowerCase().includes(searchLower)) ||
          (item.unidade_nome && item.unidade_nome.toLowerCase().includes(searchLower)) ||
          (item.responsavel_nome && item.responsavel_nome.toLowerCase().includes(searchLower)) ||
          (item.status && item.status.toLowerCase().includes(searchLower))
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.carregarAtivos();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    toggleAllSelection() {
      if (this.selectedItems.length === this.paginatedItems.length) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.paginatedItems.map((item) => item.id);
      }
    },
    abrirModalPdf() {
      if (this.selectedItems.length === 0) {
        this.showSnackbar("Nenhum item selecionado", "error");
        return;
      }
      this.pdfDialogVisible = true;
    },
    async gerarPdf() {
      if (!this.altura || !this.largura) {
        this.showSnackbar("Por favor, preencha a altura e a largura", "error");
        return;
      }

      try {
        const payload = {
          qrcode: this.selectedItems,
          altura: parseInt(this.altura),
          largura: parseInt(this.largura),
        };

        const response = await gerarQrcodePdf(payload);

        const blob = new Blob([response.data], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "qrcodes.pdf";
        link.click();

        this.showSnackbar("PDF gerado e download iniciado!", "success");
      } catch (error) {
        console.error("Erro ao gerar PDF:", error);
        this.showSnackbar("Erro ao gerar PDF", "error");
      } finally {
        this.pdfDialogVisible = false;
      }
    },
    formatarValorReal,

    ...mapActions(["selectItem"]),

    editarAtivo(item) {
      this.selectItem(item.id);
      this.$router.push({ name: "CadastroAtivos" });
    },

    async abrirMovimentarDialog(item) {
      this.selectedItem = item;
      this.movimentarDialogVisible = true;
      this.carregarUnidades();
      this.carregarLocaisFisicos();
      this.carregarUsuarios();
      this.getMotivoAlerta();
    },

    async carregarUnidades() {
      try {
        const response = await getUnidade();
        this.unidades = response.data;
      } catch (error) {
        console.error("Erro ao carregar unidades:", error);
      }
    },

    async carregarLocaisFisicos() {
      try {
        const response = await getLocalFisico();
        this.locaisFisicos = response.data;
      } catch (error) {
        console.error("Erro ao carregar locais físicos:", error);
      }
    },

    async carregarUsuarios() {
      try {
        const response = await getlistarUsuarios();
        this.usuarios = response.data;
      } catch (error) {
        console.error("Erro ao carregar Usuários:", error);
      }
    },

    async getMotivoAlerta() {
      try {
        const response = await getMotivoAlerta();
        this.listaDescarga = response.data.descargas.filter((item) => item.tipo === 2);
      } catch (error) {
        console.error("Erro ao carregar locais físicos:", error);
      }
    },

    validarCampos() {
      if (this.movimentacaoTipo === "Local") {
        if (!this.unidadeSelecionada || !this.localFisicoSelecionado) {
          this.showSnackbar("Por favor, selecione a unidade e o local físico.", "error");
          return false;
        }
      } else if (this.movimentacaoTipo === "Responsável (Presente)") {
        if (!this.email || !this.password) {
          this.showSnackbar("Por favor, preencha o email e a senha.", "error");
          return false;
        }
      } else if (this.movimentacaoTipo === "Responsável (Distante)") {
        if (!this.usuarioSelecionado) {
          this.showSnackbar("Por favor, selecione o usuário.", "error");
          return false;
        }
      } else if (this.movimentacaoTipo === "Descarga") {
        if (!this.motivoDescarga || !this.obs) {
          this.showSnackbar("Por favor, selecione o motivo e preencha a observação.", "error");
          return false;
        }
      }
      return true;
    },

    async salvarMovimentacao() {
      if (!this.validarCampos()) {
        return;
      }

      try {
        const movimentacaoDados = {
          ativo_id: this.selectedItem.id,
          usuario_id: this.usuario_id,
        };

        if (this.movimentacaoTipo === "Local") {
          movimentacaoDados.unidade_id = this.unidadeSelecionada;
          movimentacaoDados.localFisico_id = this.localFisicoSelecionado;
        } else if (this.movimentacaoTipo === "Responsável (Presente)") {
          movimentacaoDados.email = this.email;
          movimentacaoDados.password = this.password;
        } else if (this.movimentacaoTipo === "Responsável (Distante)") {
          movimentacaoDados.destino_id = this.usuarioSelecionado;
        } else if (this.movimentacaoTipo === "Descarga") {
          movimentacaoDados.obs = this.obs;
          movimentacaoDados.descarga_id = this.motivoDescarga;
        }

        await movimentarAtivo(movimentacaoDados);

        this.showSnackbar("Movimentação realizada com sucesso", "success");
        this.fecharMovimentarDialog();
        this.carregarAtivos();
      } catch (error) {
        console.error("Erro ao movimentar ativo:", error);
        this.showSnackbar("Erro ao movimentar ativo", "error");
      }
    },

    async movimentarEmUso() {
      try {
        const movimentacaoEmUso = {
          removerManutencao: 1,
          ativo_id: this.selectedItem.id,
          usuario_id: this.usuario_id,
          status: "Em uso",
        };

        await movimentarAtivo(movimentacaoEmUso);

        this.showSnackbar("Movimentação realizada com sucesso", "success");
        this.fecharMovimentarDialog();
        this.carregarAtivos();
      } catch (error) {
        console.error("Erro ao movimentar ativo:", error);
        this.showSnackbar("Erro ao movimentar ativo", "error");
      }
    },
    fecharMovimentarDialog() {
      this.movimentarDialogVisible = false;
      this.movimentacaoTipo = "";
      this.unidadeSelecionada = null;
      this.localFisicoSelecionado = null;
      this.usuarioSelecionado = null;
      this.email = "";
      this.password = "";
      this.obs = "";
      this.selectedItem = null;
      this.motivoDescarga = "";
    },

    async carregarAtivos() {
      try {
        const response = await getListarAtivos();
        this.items = response.data;
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
      }
    },

    async criarOSManutencao() {
      try {
        const dadosCriarManutencao = {
          gestao_material: 1,
          tipo_id: 4,
          ativo_id: this.selectedItem.id,
          usuario_id: this.usuario_id,
          responsavel_id: this.usuario_id,
          nome: this.selectedItem.codigo,
        };

        await gravarOrdemServico(dadosCriarManutencao);

        this.showSnackbar("Movimentação realizada com sucesso", "success");
        this.fecharMovimentarDialog();
        this.carregarAtivos();
      } catch (error) {
        console.error("Erro ao movimentar ativo:", error);
        this.showSnackbar("Erro ao movimentar ativo", "error");
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    filterItems() {
      this.currentPage = 1;
    },
    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },

    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
    },
    mostrarDetalhes(item) {
      this.selectedItem = item;
      this.detalhesDialogVisible = true;
    },
    getAnexoUrl(path) {
      return `${process.env.VUE_APP_ROOT_STORAGE}${path}`;
    },
  },

  watch: {
    search: "filterItems",
  },
};
</script>
